<template>
    <div class="reviewMain" ref='sendRecord' @scroll="scroll">
        <div>
            <!-- 上折叠 -->
            <!-- <div style="margin-top:10px;">
                <el-collapse-transition>
                    <div v-show="!collapseShow">
                        <el-row :gutter="10">
                            <el-col :span="16">
                                <el-input placeholder="请输入作者" v-model="singleSearchValue" class="input-with-select">
                                    <template slot="prepend">题目</template>
                                    <el-button slot="append" type="primary" icon="el-icon-search"
                                        @click="singleSearchPaper" style="color:#409EFF">
                                        搜索</el-button>
                                </el-input>
                            </el-col>
                            <el-col :span="8">
                                <el-button type="text" @click="collapseShow = !collapseShow" icon="el-icon-arrow-right">
                                    更多搜索条件</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-collapse-transition>
            </div> -->
            <!-- 下折叠 -->
            <div>
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="单位:">
                        <el-select v-model="search.institutionId" size="medium" clearable @change="handleInstitution"
                            placeholder="请选择单位" class="form-line-item">
                            <el-option v-for="(item,index) in institutionList" :key='index'
                                :label="item.institutionName" :value="item.institutionId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="批次:">
                        <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次"
                            class="form-line-item" :disabled='batchShow'>
                            <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                                :value="item.batchId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专家单位:">
                        <el-input placeholder="请输入专家单位" v-model="search.student" size='medium' class="form-line-item">
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-form :inline="true" class="demo-form-inline">
                    <!-- <el-form-item label="编号:">
                        <el-input placeholder="请输入论文编号" v-model="search.oldId" size='medium' class="form-line-item">
                        </el-input>
                    </el-form-item> -->
                    <el-form-item label="专家:">
                        <el-input placeholder="请输入专家姓名" v-model="search.student" size='medium' class="form-line-item">
                        </el-input>
                    </el-form-item>

                    <el-form-item style="margin-left:10px;">
                        <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
                            搜索
                        </el-button>
                        <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                            重置
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <!-- 表格 -->
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <el-button type="success" size="small" @click="sendEmailHandle">发送邀审信息</el-button>
                    <!-- <el-button type="success" size="small" @click="sendEmailHandle">匹配专家</el-button> -->
                </template>
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar paperList" round align="left"
                ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading'
                :data="paperList">
                <vxe-column type='checkbox' width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="expertName" title="姓名" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="university" title="单位" width="180" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="resultKeywords" title="学科" width="180" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="expertTitle" title="职称" width="150" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="expertType" title="导师类型" width="150" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="invitationNum" title="邀请数" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="acceptNum" title="接受数" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="submitNum" title="提交数" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column title="操作" min-width="200">
                    <template #default="{ row }">
                        <el-button size="small" type="text"
                            @click="handleSingleCondition(row.paperId,row.batchId,row.title)">送审情况</el-button>
                    </template>
                </vxe-column>
            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                :total="page.total" :page-sizes="page.pageSizes"
                :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>
        <!-- 弹窗 -->
        <vxe-modal v-model="conditionModel" :position="{top: '0px'}" width="80%" height="100%" :show-footer='true'
            show-zoom resize :transfer='true'>
            <template #title>
                送审情况
            </template>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <el-button type="success" size="small">新增论文</el-button>
                </template>
            </vxe-toolbar>
            <vxe-table class="mytable-scrollbar" :header-cell-style="headerCellStyle" :cell-style='cellStyle' round
                align="left" :data="receiptList" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
                <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="title" title="论文题目" min-width="300" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="resultKeywords" title="匹配结果关键词" min-width="300" :show-overflow="'tooltip'">
                </vxe-column>
                <vxe-column field="score" title="分数" width="100"></vxe-column>
                <vxe-column field="statue" title="状态" width="100" :show-overflow="'tooltip'">
                </vxe-column>
                <vxe-column field="operate" title="操作" width="200">
                    <template #default="{ row }">
                        <!-- <el-button type="text" size="small" @click="insertDia(row.group)">新增</el-button> -->
                        <el-button type="text" size="small" @click="revokeHandle(row.group)">撤销</el-button>
                    </template>
                </vxe-column>
            </vxe-table>
            <template #footer>
                <el-button @click="cfmInsertSchoolHandle" type="primary" size="small">确认</el-button>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
    // import dateFormat from '../../../public/tools/date'
    export default {
        name: 'ReviewPage',
        data() {
            return {
                //数据加载
                loading: true,
                //输入条件
                input3: '',
                select: '1',
                //折叠面板显示与
                collapseShow: false,
                //搜索条件
                institutionList: [],
                batchList: [],
                singleSearchLabel: 'title',
                singleSearchValue: '',
                student: '',
                search: {
                    institutionId: '',
                    batchId: '',
                    title: '',
                    oldId: '',
                    student: '',
                    statue: '',
                }, // 专家姓名，专家单位
                batchShow: true,
                //论文列表
                paperList: [{
                    expertName: '史唅',
                    university: '南京理工大学',
                    expertTitle: '正高级职称',
                    expertType: '博士生导师',
                    resultKeywords: '计算机科学与技术',
                    invitationNum: 4,
                    acceptNum: 4,
                    submitNum: 3
                }],
                //分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [1, 5, 10, 15, 20],
                    total: 100
                },
                //送审情况弹窗
                conditionModel: false,
                //评阅书列表
                receiptList: [{
                        paperId: 1,
                        title: '论文题目论文题目论文题目论文题目-1',
                        institutionName: '南京理工大学',
                        batchName: '2021硕士第一批',
                        endTime: '2022-10-10',
                        resultKeywords: '关键词1;关键词2;关键词3;关键词;',
                        score: '100',
                        statue: '已提交',
                    },
                    {
                        paperId: 1,
                        title: '论文题目论文题目论文题目论文题目-1',
                        institutionName: '南京理工大学',
                        batchName: '2021硕士第一批',
                        endTime: '2022-10-10',
                        resultKeywords: '关键词1;关键词2;关键词3;关键词;',
                        score: '100',
                        statue: '已提交',
                    }
                ],
                paperKeywords: '',
                //发送进度条
                sendProcessVis: false,
                showClose: false,
                sendProcessTitle: '发送中',
                process: 0,
                sendTimer: ''
            }
        },
        mounted() {},
        activated() {
            this.$nextTick(() => {
                console.log(this.$store.state.sendRecord.scrollTop);
                this.$refs.sendRecord.parentElement.scrollTop = this.$store.state.sendRecord.scrollTop;
            })
        },
        created() {
            this.loading = false;
            //获取送审单位
            this.getInstitution();
            //获取论文列表
            this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            this.loading = false;
            //开启定时任务
            // this.timer();
        },
        methods: {
            //表格刷新
            refresh() {
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //定时任务
            timer() {
                return setInterval(() => {
                    this.getTimePaperList(this.search, this.page.currentPage, this.page.pageSize);
                }, 60000)
            },
            //获取送审单位
            getInstitution() {
                this.$axios.get("/school/getAllInstitution")
                    .then(res => {
                        console.log(res.data.data);
                        this.institutionList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            //获取送审单位的批次
            getBatch(institutionId) {
                let param = new URLSearchParams();
                param.append("institutionId", institutionId)
                this.$axios.get("/school/get_constraint_by_institutionId", {
                        params: param
                    })
                    .then(res => {
                        console.log(res.data.data);
                        this.batchList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护");
                    });
            },
            //机构选择改变
            handleInstitution(val) {
                console.log(val);
                if (val == '') {
                    this.batchShow = true;
                    return;
                }
                this.batchShow = false;
                this.getBatch(val);
            },
            //定时器获取论文列表
            getTimePaperList(search, pageIndex, pageSize) {
                let param = new URLSearchParams();
                param.append("institutionId", search.institutionId);
                param.append("batchId", search.batchId);
                param.append("statue", search.statue);
                param.append("title", search.title);
                param.append("oldId", search.oldId);
                param.append("student", search.student);
                // param.append("startTime", dateFormat.dateFormat(search.time[0]));
                // param.append("endTime", dateFormat.dateFormat(search.time[1]));
                param.append("pageIndex", pageIndex);
                param.append("pageSize", pageSize);
                this.$axios.get("/sending_paper/get_condition", {
                    params: param
                }).then(res => {
                    console.log(res.data);
                    this.paperList = res.data.data;
                    this.page.total = res.data.count;
                }).catch(err => {
                    this.$message.warning("服务器维护！");
                });
            },
            //获取论文列表
            getPaperList(search, pageIndex, pageSize) {
                this.loading = true;
                // let param = new URLSearchParams();
                // param.append("institutionId", search.institutionId);
                // param.append("batchId", search.batchId);
                // param.append("statue", search.statue);
                // param.append("title", search.title);
                // param.append("oldId", search.oldId);
                // param.append("student", search.student);
                // param.append("pageIndex", pageIndex);
                // param.append("pageSize", pageSize);
                // this.$axios.get("/sending_paper/get_condition", {
                //     params: param
                // }).then(res => {
                //     console.log(res.data);
                //     this.paperList = res.data.data;
                //     this.page.total = res.data.count;
                //     this.loading = false;
                // }).catch(err => {
                //     this.$message.warning("服务器维护！");
                // });
                this.loading = false;
            },
            //查看单篇论文的送审情况
            handleSingleCondition(paperId, batchId, paperName) {
                this.conditionModel = true;
                this.$store.dispatch('changePaperId', paperId);
                this.$store.dispatch("changeBatchId", batchId);
                this.$store.dispatch("changePaperName", paperName);
                // this.$router.push({
                //     name: 'checkSendRecord'
                // });
            },
            //发送评阅书
            sendEmailHandle(paperId) {
                //获取已选中的论文
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                if (selectRecords.length <= 0) {
                    this.$message.warning('请选择论文！');
                    return;
                }
                // this.process = 0;
                // this.sendProcessVis = true;
                const loading = this.$loading({
                    lock: true,
                    text: '正在发送',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let paperIds = [];
                selectRecords.forEach((item) => {
                    paperIds.push(item.paperId);
                });
                let param = {
                    paperIds: paperIds.join(',')
                };

                this.$axios.post("/sending_paper/send_email", param)
                    .then(res => {
                        // clearInteral(this.sendTimer);
                        loading.close();
                        this.showClose = true;
                        if (res.data.code == 1) {
                            this.process = 100;
                            this.sendProcessTitle = '发送成功';
                            this.$message.success("发送成功");
                        } else {
                            this.process = 99;
                            this.sendProcessTitle = '发送失败';
                            this.$message.warning("发送失败");
                        }
                    })
                    .catch(err => {
                        loading.close();
                        this.$message.warning("服务器维护！");
                    });
            },
            //获取发送精度
            getProcess() {
                this.$axios.get('sending_paper/progress')
                    .then(res => {
                        this.process = res.data.data;
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            batchSearchPaper() {
                console.log(this.search);
                this.page.currentPage = 1;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
                this.collapseShow = !this.collapseShow;
            },
            resetHandle() {
                this.search = {
                    institutionId: '',
                    batchId: '',
                    title: '',
                    oldId: '',
                    student: '',
                    statue: '',
                };
            },
            singleSearchPaper() {
                this.search['title'] = this.singleSearchValue;
                this.search['student'] = '',
                    this.search['batchId'] = '',
                    this.search['statue'] = '',
                    this.search['time'] = ['', ''],
                    this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //获取评阅书
            handleLookReview(paperId) {
                this.receiptDiaVis = true;
            },
            //查看评阅书
            handleLook(url) {
                window.open(url);
            },
            //下载评阅书
            handleDownloadReview() {

            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                console.log(currentPage, pageSize);
                this.page.currentPage = currentPage;
                this.page.pageSize = pageSize;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //每页多少数据改变
            handleChangePageSize(val) {
                this.page.pageSize = val;
                this.page.currentPage = 1;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            firstPage() {
                this.page.currentPage = 1;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            endPage() {
                this.page.currentPage = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
                    .pageSize == 0 ? 0 : 1);
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //获取选中的论文
            getSelectEvent() {
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();

                console.log(selectRecords);
            },
            headerCellClassName({
                column,
                columnIndex
            }) {
                return 'vxe-table-thead'
            },
            headerCellStyle({
                row,
                column,
                rowIndex,
                columnIndex
            }) {
                return "background-color:#e0e0e0f1"
            },
            scroll(e) {
                console.log('scroll', e.target.scrollTop)
            }

        },
        destroyed() {
            clearInterval(this.timer);
        },
        //在页面离开时记录滚动位置
        beforeRouteLeave(to, from, next) {
            this.$store.dispatch('changeScrollTop', this.$refs.sendRecord.parentElement.scrollTop);
            next()
        },
        //进入该页面时，用之前保存的滚动位置赋值
        // beforeRouteEnter(to, from, next) {
        //     next(vm => {
        //         console.log(vm.scrollTop);
        //         console.log(vm.$refs)
        //     })
        // },
        components: {

        }
    }
</script>

<style scoped>
    .inputSelect {
        width: 120px;
    }

    .collapseInput {
        margin-top: 20px;
    }

    .searchBtn {
        padding: 20px 0px 0px 0px;
        text-align: center;
    }

    .box-card {
        padding-bottom: 0px !important;
    }

    .input-with-select .el-input-group__prepend {
        background-color: #fff;
    }

    .form-line-item {
        width: 15em;
    }

    .el-form-item {
        margin-bottom: 10px !important;
    }
</style>
<style>
    .paperList .vxe-table--body-wrapper {
        height: calc(100vh - 391px);
    }

    .dialog .el-dialog {
        margin-top: 5vh !important;
        border-radius: 15px;
    }

    .searchForm .el-form-item__label {
        background-color: #F5F7FA;
        color: #909399;
    }
</style>