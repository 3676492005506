<template>
    <div ref='sendRecord' @scroll="scroll">
        <div>
            <el-form :inline="true" class="demo-form-inline">
                <!-- <el-form-item label="单位:">
                    <el-select v-model="search.institutionId" size="medium" clearable @change="handleInstitution"
                        placeholder="请选择单位" class="form-line-item">
                        <el-option v-for="(item,index) in institutionList" :key='index' :label="item.institutionName"
                            :value="item.institutionId"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="批次:">
                    <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次"
                        class="form-line-item">
                        <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                            :value="item.batchId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="论文题目:">
                    <el-input placeholder="请输入论文题目" style="width:350px;" v-model="search.title" size='medium'
                        class="form-line-item">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :inline="true" class="demo-form-inline">
                <!-- <el-form-item label="编号:">
                        <el-input placeholder="请输入论文编号" v-model="search.oldId" size='medium' class="form-line-item">
                        </el-input>
                    </el-form-item> -->
                <el-form-item label="作者:">
                    <el-input placeholder="请输入作者" v-model="search.author" size='medium' class="form-line-item">
                    </el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="search.state" size="medium" clearable placeholder="请选择状态"
                        class="form-line-item">
                        <el-option label="已审核" :value="2"></el-option>
                        <el-option label="未审核" :value="0"></el-option>
                        <el-option label="已发布" :value="1"></el-option>
                        <el-option label="未发布" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="margin-left:10px;">
                    <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
                        搜索
                    </el-button>
                    <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- 表格 -->
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <el-button type="success" size="mini" @click="exportReviewHandle">批量导出评阅书</el-button>
                    <el-button type="success" size="mini">批量打印评阅书</el-button>
                    <el-button type="success" size="mini" @click="exportTableHandle">导出评审信息表</el-button>
                </template>
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar reviewList" round align="left"
                ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading'
                :data="paperList">
                <vxe-column type='checkbox' width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="title" title="题目-序号" min-width="300" :show-overflow="'tooltip'"></vxe-column>
                <!-- <vxe-column field="institutionName" title="单位" width="180" :show-overflow="'tooltip'"></vxe-column> -->
                <vxe-column field="batchName" title="批次" width="180" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="institutionName" title="学生姓名" width="180" :show-overflow="'tooltip'"></vxe-column>

                <vxe-column field="isCheck" title="状态" width="100" :show-overflow="'tooltip'">
                    <template #default="{ row }">
                        评审中
                    </template>
                </vxe-column>
                <!-- <vxe-column field="isPublish" title="是否发布" width="100">
                    <template #default="{ row }">
                        {{row.isPublish==1?'是':'否'}}
                    </template>
                </vxe-column> -->
                <vxe-column title="操作" width="250">
                    <template #default="{ row }">
                        <el-button size="small" type="text">下载评阅书</el-button>
                        <!--<el-button size="small" type="text">确认审核</el-button>
                        <el-button size="small" type="text" @click="updateReviewHandle">更新评阅书</el-button> -->
                    </template>
                </vxe-column>
            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.pageIndex" :page-size.sync="page.pageSize"
                :total="page.total" :page-sizes="page.pageSizes"
                :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>
        <!-- 弹窗 -->
        <vxe-modal v-model="exportReviewModel" :position="{top: '0px'}" width="500" height="350" :show-footer='true'
            show-zoom resize :transfer='true'>
            <template #title>
                导出评阅书
            </template>
            <vxe-form :data="download" title-align="right" title-width="250">
                <vxe-form-item field="sendType" title="已选评阅书数：" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <span style="color:red;">{{download.reviewNum}}</span> 份
                    </template>
                </vxe-form-item>
                <vxe-form-item field="sendType" title="是否包含评审人信息：" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-radio-group v-model="data.withReviewer">
                            <vxe-radio :label="1" content="是" style="width:70px;"></vxe-radio>
                            <vxe-radio :label="0" content="否" style="width:70px;"></vxe-radio>
                        </vxe-radio-group>
                    </template>
                </vxe-form-item>
                <vxe-form-item title="评阅书导出文件格式：" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-checkbox-group v-model="data.format">
                            <vxe-checkbox label="WORD" content="WORD" style="width:70px;"></vxe-checkbox>
                            <vxe-checkbox label="PDF" content="PDF" style="width:70px;"></vxe-checkbox>
                        </vxe-checkbox-group>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="paperType" title="评阅书导出范围：" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-radio-group v-model="data.range">
                            <vxe-radio :label="1" content="是" style="width:70px;"></vxe-radio>
                            <vxe-radio :label="0" content="否" style="width:70px;"></vxe-radio>
                        </vxe-radio-group>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <template #footer>
                <el-button type="primary" size="small">确认</el-button>
            </template>
        </vxe-modal>
        <!-- 导出评阅表弹窗 -->
        <vxe-modal v-model="exportTableModel" :position="{top: '0px'}" width="800" :show-footer='true' show-zoom resize
            :transfer='true'>
            <template #title>
                导出评审信息表
            </template>
            <vxe-form :data="download" title-align="right" title-width="200">
                <vxe-form-item title="选择导出评审表格式：" :span="12" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-select v-model="data.format" placeholder="请选择" @change="changeHandle" size="medium"
                            transfer clearable>
                            <vxe-option value="表1" label="表1"></vxe-option>
                            <vxe-option value="表2" label="表2"></vxe-option>
                            <vxe-option value="表3" label="表3"></vxe-option>
                            <vxe-option value="自定义" label="自定义"></vxe-option>
                        </vxe-select>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <el-form class="editName">
                <el-form-item label="论文信息：" label-width="20%">
                    <el-checkbox-group v-model="formData.paperInfos">
                        <el-checkbox v-for="item in paperInfos" :label="item"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="专家信息：" label-width="20%">
                    <el-checkbox-group v-model="formData.expertInfos">
                        <el-checkbox v-for="item in expertInfos" :label="item"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="评阅书信息：" label-width="20%">
                    <el-checkbox-group v-model="formData.reviewInfos">
                        <el-checkbox v-for="item in reviewInfos" :label="item"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmExportSave'>导出并预存</el-button>
                <el-button type="primary" size="small" @click='cfmExport'>确认</el-button>
            </template>
        </vxe-modal>
        <vxe-modal v-model="exportTableSaveModel" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom
            resize :transfer='true'>
            <template #title>
                保存为新评审信息表
            </template>
            <vxe-form :data="download" title-align="right" title-width="150">
                <vxe-form-item title="预存名称：" :span="20" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.format" placeholder="请输入文本" type="text"></vxe-input>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmExport'>确认</el-button>
            </template>
        </vxe-modal>
        <vxe-modal v-model="updataReviewModel" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom resize
            :transfer='true'>
            <template #title>
                更新评阅书
            </template>
            <el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/" multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传doc文件，且不超过500kb</div>
            </el-upload>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmExport'>确认</el-button>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
    // import dateFormat from '../../../public/tools/date'
    export default {
        name: '',
        data() {
            return {
                //数据加载
                loading: true,
                //输入条件
                input3: '',
                select: '1',
                //折叠面板显示与
                collapseShow: false,
                //搜索条件
                institutionList: [],
                batchList: [],
                singleSearchLabel: 'title',
                singleSearchValue: '',
                student: '',
                search: {
                    institutionId: '',
                    batchId: '',
                    title: '',
                    oldId: '',
                    author: '',
                    state: '',
                },
                batchShow: true,
                //论文列表
                paperList: [{
                    paperId: 1,
                    title: '论文题目论文题目论文题目论文题目-1',
                    institutionName: '南京理工大学',
                    batchName: '2021硕士第一批',
                    isCheck: 1,
                    isPublish: 0
                }],
                //分页
                page: {
                    pageIndex: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [1, 5, 10, 15, 20],
                    total: 100
                },
                //送审情况弹窗
                conditionModel: false,
                // 导出评阅书弹窗
                exportReviewModel: false,
                //下载对话框中的信息
                download: {
                    withReviewer: 1,
                    format: ['WORD'],
                    range: 0,
                    reviewNum: '0',
                    paperNum: '0'
                },
                // 导出评审信息表
                exportTableModel: false,
                // 导出并预存弹窗
                exportTableSaveModel: false,
                // 更新评阅书
                updataReviewModel: false,
                //评阅书列表
                receiptList: [{
                        isSelected: '1',
                        group: '1',
                        expertName: '史唅',
                        university: '南京理工大学',
                        expertTitle: '正高级职称',
                        expertType: '博士生导师',
                        resultKeywords: '关键词1;关键词2;关键词3;关键词;',
                        score: '100',
                        statue: '已提交',
                    },
                    {
                        isSelected: '1',
                        group: '1',
                        expertName: '史唅',
                        university: '南京理工大学',
                        expertTitle: '正高级职称',
                        expertType: '博士生导师',
                        resultKeywords: '关键词1;关键词2;关键词3;关键词;',
                        score: '100',
                        statue: '已提交',
                    }
                ],
                formData: {
                    paperInfos: [],
                    expertInfos: [],
                    reviewInfos: [],
                },
                paperInfos: ['论文编号', '论文名称', '学生姓名', '学生专业', '学位类型', '导师姓名'],
                expertInfos: ['专家姓名', '专家单位', '专家职称', '专家类型', '专家邮箱', '专家手机号'],
                reviewInfos: ['论文选题', '论文综述', '独立解决实际问题的能力', '工作量', '技术难度与先进性', '新思想或新方法经济效益或社会效益', '结构严谨性', '语言通顺性',
                    '论文写作水平正确性', '论文写作水平规范性', '综合意见', '总体评价', '熟悉程度'
                ],
                paperKeywords: '',
                //发送进度条
                sendProcessVis: false,
                showClose: false,
                sendProcessTitle: '发送中',
                process: 0,
                sendTimer: ''
            }
        },
        mounted() {},
        // activated() {
        //     this.$nextTick(() => {
        //         console.log(this.$store.state.sendRecord.scrollTop);
        //         this.$refs.sendRecord.parentElement.scrollTop = this.$store.state.sendRecord.scrollTop;
        //     })
        // },
        created() {
            console.log(1231212);
            this.getAllBatch();
            //获取论文列表
            this.getPaperList();
            //开启定时任务
            // this.timer();
        },
        methods: {
            // 获取所有批次
            getAllBatch() {
                this.$api.paperLibrary.getAllBatch()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.batchList = res.data.data;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            // 获取论文列表
            getPaperList() {
                this.loading = true;
                let params = {
                    batchId: this.search.batchId,
                    title: this.search.title,
                    state: this.search.state,
                    author: this.search.author,
                    pageIndex: this.page.pageIndex,
                    pageSize: this.page.pageSize
                }
                this.$api.sendManage.getP2pResult(params).then(res => {
                    if (res.data.code == 200) {
                        this.paperList = res.data.data;
                        this.page.total = res.data.count;
                        this.loading = false;
                    }
                }).catch(err => {
                    this.$message.warning("服务器维护！");
                });
            },
            //导出评阅书
            exportReviewHandle() {
                this.exportReviewModel = true;
            },
            exportTableHandle() {
                this.exportTableModel = true;
            },
            changeHandle(item) {
                // if(item.value == '自定义')
                // {

                // }
            },
            cfmExportSave() {
                this.exportTableSaveModel = true;
            },
            // 发布评阅书
            publishReview() {
                let paperNum = 0;
                let reviewNum = 0;
                this.$confirm('已选 ' + paperNum + ' 篇论文，共 ' + reviewNum + ' 个评阅书', '提示', {
                        confirmButtonText: '发布',
                        cancelButtonText: '取消',
                        type: 'warning',
                        center: true
                    }).then(res => {
                        let loading = this.$loading({
                            lock: true,
                            text: '发布中',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        var par = {
                            paperIds: this.paperIds
                        };
                        this.$axios.post('/sending_paper/publish_review', par).then(res => {
                            loading.close();
                            if (res.data.data == 1) {
                                this.$message.success("发布成功！");
                                this.getPaperList(this.search, this.page.currentPage, this.page
                                    .pageSize);
                            } else {
                                this.$message.warning("发布失败！");
                            }
                        }).catch(err => {
                            this.$message.warning('服务器维护');
                        })
                    })
                    .catch(err => {})
            },
            updateReviewHandle() {
                this.updataReviewModel = true;
            },
            //表格刷新
            refresh() {
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //获取送审单位的批次
            getBatch(institutionId) {
                let param = new URLSearchParams();
                param.append("institutionId", institutionId)
                this.$axios.get("/school/get_constraint_by_institutionId", {
                        params: param
                    })
                    .then(res => {
                        console.log(res.data.data);
                        this.batchList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护");
                    });
            },
            //机构选择改变
            handleInstitution(val) {
                console.log(val);
                if (val == '') {
                    this.batchShow = true;
                    return;
                }
                this.batchShow = false;
                this.getBatch(val);
            },
            batchSearch() {},
            //获取论文列表

            //查看单篇论文的送审情况
            handleSingleCondition(paperId, batchId, paperName) {
                this.conditionModel = true;
                this.$store.dispatch('changePaperId', paperId);
                this.$store.dispatch("changeBatchId", batchId);
                this.$store.dispatch("changePaperName", paperName);
                // this.$router.push({
                //     name: 'checkSendRecord'
                // });
            },
            //发送评阅书
            sendEmailHandle(paperId) {
                //获取已选中的论文
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                if (selectRecords.length <= 0) {
                    this.$message.warning('请选择论文！');
                    return;
                }
                // this.process = 0;
                // this.sendProcessVis = true;
                const loading = this.$loading({
                    lock: true,
                    text: '正在发送',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let paperIds = [];
                selectRecords.forEach((item) => {
                    paperIds.push(item.paperId);
                });
                let param = {
                    paperIds: paperIds.join(',')
                };

                this.$axios.post("/sending_paper/send_email", param)
                    .then(res => {
                        // clearInteral(this.sendTimer);
                        loading.close();
                        this.showClose = true;
                        if (res.data.code == 1) {
                            this.process = 100;
                            this.sendProcessTitle = '发送成功';
                            this.$message.success("发送成功");
                        } else {
                            this.process = 99;
                            this.sendProcessTitle = '发送失败';
                            this.$message.warning("发送失败");
                        }
                    })
                    .catch(err => {
                        loading.close();
                        this.$message.warning("服务器维护！");
                    });
            },
            //获取发送精度
            getProcess() {
                this.$axios.get('sending_paper/progress')
                    .then(res => {
                        this.process = res.data.data;
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            batchSearch() {
                console.log(this.search);
                this.page.currentPage = 1;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
                this.collapseShow = !this.collapseShow;
            },
            resetHandle() {
                this.search = {
                    institutionId: '',
                    batchId: '',
                    title: '',
                    oldId: '',
                    student: '',
                    statue: '',
                };
            },
            singleSearchPaper() {
                this.search['title'] = this.singleSearchValue;
                this.search['student'] = '',
                    this.search['batchId'] = '',
                    this.search['statue'] = '',
                    this.search['time'] = ['', ''],
                    this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //获取评阅书
            handleLookReview(paperId) {
                this.receiptDiaVis = true;
            },
            //查看评阅书
            handleLook(url) {
                window.open(url);
            },
            //下载评阅书
            handleDownloadReview() {

            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                console.log(currentPage, pageSize);
                this.page.currentPage = currentPage;
                this.page.pageSize = pageSize;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //每页多少数据改变
            handleChangePageSize(val) {
                this.page.pageSize = val;
                this.page.currentPage = 1;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            firstPage() {
                this.page.currentPage = 1;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            endPage() {
                this.page.currentPage = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
                    .pageSize == 0 ? 0 : 1);
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //获取选中的论文
            getSelectEvent() {
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();

                console.log(selectRecords);
            },
            headerCellClassName({
                column,
                columnIndex
            }) {
                return 'vxe-table-thead'
            },
            headerCellStyle({
                row,
                column,
                rowIndex,
                columnIndex
            }) {
                return "background-color:#e0e0e0f1"
            },
            scroll(e) {
                console.log('scroll', e.target.scrollTop)
            }

        },
        destroyed() {
            clearInterval(this.timer);
        },
        //在页面离开时记录滚动位置
        beforeRouteLeave(to, from, next) {
            this.$store.dispatch('changeScrollTop', this.$refs.sendRecord.parentElement.scrollTop);
            next()
        },
        components: {

        }
    }
</script>

<style scoped>
    .inputSelect {
        width: 120px;
    }

    .collapseInput {
        margin-top: 20px;
    }

    .searchBtn {
        padding: 20px 0px 0px 0px;
        text-align: center;
    }

    .box-card {
        padding-bottom: 0px !important;
    }

    .input-with-select .el-input-group__prepend {
        background-color: #fff;
    }

    .form-line-item {
        width: 15em;
    }

    .el-form-item {
        margin-bottom: 10px !important;
    }
</style>
<style>
    .reviewList .vxe-table--body-wrapper {
        height: calc(100vh - 336px);
    }

    .dialog .el-dialog {
        margin-top: 5vh !important;
        border-radius: 15px;
    }

    .searchForm .el-form-item__label {
        background-color: #F5F7FA;
        color: #909399;
    }
</style>